<template>
    <b-row class="match-height">
      <b-col
lg="12"
> <b-card
      :title="'Confirm Purchase Request By ' + formatRole(currentRole)"
    >
      <b-form
          @submit.prevent="submitConfirmPurchaseRequest"
>
        <b-row>
          <b-col cols="12">
            <b-button
            type="button"
            variant="primary"
            class="mr-1"
            @click="previous()"
            >
            <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
            />
            Back
            </b-button>
            <div class="devider"/>
            <b-col cols="12">
            <b-form-group
              label="Number"
              label-for="payment_request_number"
              label-cols-md="12"
            >
              <b-form-input
                id="payment_request_number"
                v-model="paymentRequestNumber"
                placeholder="Project"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Project"
              label-for="payment_request_project"
              label-cols-md="12"
            >
              <b-form-input
                id="payment_request_project"
                v-model="paymentRequestProject"
                placeholder="Project"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Currency"
              label-for="payment_request_currency"
              label-cols-md="12"
            >
              <b-form-input
                id="payment_request_currency"
                v-model="paymentRequestCurrency"
                placeholder="Currency"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Supplier"
              label-for="payment_request_supplier"
              label-cols-md="12"
            >
              <b-form-input id="payment_request_supplier" v-model="paymentRequestSupplier" readonly />
            </b-form-group>
            <b-form-group
              label="Bank"
              label-for="payment_request_bank"
              label-cols-md="12"
            >
              <b-form-input id="payment_request_bank" v-model="paymentRequestBank" readonly />
            </b-form-group>
            <b-form-group
              label="Bank Number"
              label-for="payment_request_bank"
              label-cols-md="12"
            >
              <b-form-input id="payment_request_bank_number" v-model="paymentRequestBankNumber" readonly />
            </b-form-group>
            <b-form-group
              label="Bank Owner"
              label-for="payment_request_bank_owner"
              label-cols-md="12"
            >
              <b-form-input id="payment_request_bank_owner" v-model="paymentRequestBankOwner" readonly />
            </b-form-group>
            <b-form-group
              label="Notes"
              label-for="payment_request_notes"
              label-cols-md="12"
            >
              <b-form-input
                id="payment_request_notes"
                v-model="paymentRequestNotes"
                placeholder="Notes"
                readonly
              />
            </b-form-group>
              <b-row>
                <b-col cols="2" class="text-center font-weight-bold">
                  Division
                </b-col>
                <b-col cols="1" class="text-center font-weight-bold">
                  Cost Center
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Facture Number
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Description
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Due Date
                </b-col>
                <b-col cols="3" class="text-center font-weight-bold">
                  Nominal
                </b-col>
              </b-row>
            <p />
            <b-row v-for="chosen in chosens" :key="chosen.value">
              <br />
              <b-col cols="2 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
                    {{ capitalizeWords(chosen.payment_request_detail_divisi) }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col cols="1 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
                    {{ capitalizeWords(chosen.payment_request_detail_cost_center) }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col cols="2 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
                    {{ capitalizeWords(chosen.payment_request_detail_facture_number) }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col cols="2 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
                    {{ capitalizeWords(chosen.payment_request_detail_description) }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col cols="2 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
                    {{ dateFormat(chosen.payment_request_detail_due_date) }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col cols="3 text-center">
                  <b-list-group-item href="#">
                    {{paymentRequestCurrencySymbol}}{{ numberFormat(chosen.payment_request_detail_nominal) }}
                  </b-list-group-item>
              </b-col>
              <b-col cols="12">
                <br />
              </b-col>
            </b-row>
            <b-form-group>
              <label for="no_invoice">Status :</label>
              <vue-horizontal-timeline :items="statusItems" />
            </b-form-group>
          </b-col>
          <b-col offset-md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="submitButtonDisabled"
            >
              Confirm
            </b-button>
          </b-col>
          </b-col>
          <!-- submit and reset -->
        </b-row>
      </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"

  import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const statusItems = []
  const paymentRequestProject = ""
  const paymentRequestNumber = ""
  const paymentRequestCurrency = ""
  const paymentRequestCurrencySymbol = ""
  const paymentRequestSupplier = ""
  const paymentRequestBank = ""
  const paymentRequestBankNumber = ""
  const paymentRequestBankOwne = ""
  const paymentRequestNotes = ""
  const currentRole = ""
  const submitButtonDisabled = false
  export default {
    components: {
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        statusItems,
        submitButtonDisabled,
        paymentRequestProject,
        paymentRequestNumber,
  paymentRequestCurrency,
  paymentRequestCurrencySymbol,
  paymentRequestSupplier,
  paymentRequestBank,
  paymentRequestBankNumber,
  paymentRequestBankOwne,
  paymentRequestNotes,
  currentRole,
    VersionCheck,
    items,
    chosenItem,
    chosens,
      }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
    numberFormat(value) {
    if (Number.isNaN(value)) return "0,00"
    return new Intl.NumberFormat('id-ID', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)
  },
      capitalizeWords(str) {
    // Split the string into words using spaces as the separator
    const words = str.split(' ')
    // Capitalize the first letter of each word and join them back together
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })
    // Join the capitalized words into a single string with spaces
    return capitalizedWords.join(' ')
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
      formatRole(role) {
    if (!role) return ''
    return role.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  },
    getDetail() {
      const roleConfirm = localStorage.getItem('paymentRequestConformRole')
      this.currentRole = roleConfirm
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const decoded = JSON.parse(localStorage.getItem("paymentRequestDetails"))
      this.paymentRequestProject = decoded.payment_request_project_name
      this.paymentRequestNumber = decoded.payment_request_number
      this.paymentRequestCurrency = decoded.currency.currency_full_name
      this.paymentRequestCurrencySymbol = decoded.currency.currency_symbol
      this.paymentRequestSupplier = decoded.supplier.contact_name
      this.paymentRequestBank = decoded.payment_request_bank
      this.paymentRequestBankNumber = decoded.payment_request_bank_number
      this.paymentRequestBankOwner = decoded.payment_request_bank_owner
      this.paymentRequestNotes = decoded.payment_request_note
      let obj0 = null
                  let obj1 = null
                  let obj2 = null
                  let obj3 = null
                  let obj4 = null
                  let obj5 = null
                  let obj6 = null
                  let obj7 = null
                    obj1 = {
                      title: "Created",
                      content: `Payment Request Has been Created By ${
                        decoded.creator.user_fullname
                      } at ${this.dateSimple(
                        decoded.payment_request_created_time,
                        "YYYY-MM-DD",
                      )}`,
                      stepCssClass: "has-step-green",
                      boxCssClass: "has-color-red",
                    }
                    obj0 = {
                      title: "Lab Manager",
                      content:
                        decoded.payment_request_confirmed_by_lab_manager !== null
                          ? `Payment Request Has been Accepted by ${
                              decoded.lab_manager.user_fullname
                            } at ${this.dateSimple(
                              decoded.payment_request_confirmed_by_lab_manager_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Payment Request Has not been Accepted By Lab Manager`,
                      stepCssClass:
                        decoded.payment_request_confirmed_by_lab_manager !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj2 = {
                      title: "Regional Manager",
                      content:
                        decoded.payment_request_confirmed_by_area_manager !== null
                          ? `Payment Request Has been Accepted by ${
                              decoded.area_manager.user_fullname
                            } at ${this.dateSimple(
                              decoded.payment_request_confirmed_by_area_manager_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Payment Request Has not been Accepted By Regional Cabang`,
                      stepCssClass:
                        decoded.payment_request_confirmed_by_area_manager !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj3 = {
                      title: "Division Head",
                      content:
                        decoded.payment_request_confirmed_by_division_head !== null
                          ? `Payment Request Has been Accepted by ${
                              decoded.division_head.user_fullname
                            } at ${this.dateSimple(
                              decoded
                                .payment_request_confirmed_by_division_head_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Payment Request Has not been Accepted By Division Head`,
                      stepCssClass:
                        decoded.payment_request_confirmed_by_division_head !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj4 = {
                      title: "FA Coal Manager",
                      content:
                        decoded.payment_request_confirmed_by_fa_coal_manager !== null
                          ? `Payment Request Has been Accepted by ${
                              decoded.area_manager.user_fullname
                            } at ${this.dateSimple(
                              decoded
                                .payment_request_confirmed_by_fa_coal_manager_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Payment Request Has not been Accepted By FA Coal Manager`,
                      stepCssClass:
                        decoded.payment_request_confirmed_by_fa_coal_manager !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }

                    this.statusItems = []
                    this.statusItems.push(obj1)
                    this.statusItems.push(obj0)
                    this.statusItems.push(obj2)
                    this.statusItems.push(obj3)
                    this.statusItems.push(obj4)
                    if (decoded.payment_request_amount_total > 50000000) {
                    obj5 = {
                      title: "Operational Director",
                      content:
                        decoded.payment_request_confirmed_by_operational_director !== null
                          ? `Payment Request Has been Accepted by ${
                              decoded.division_head.user_fullname
                            } at ${this.dateSimple(
                              decoded
                                .payment_request_confirmed_by_operational_director_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Payment Request Has not been Accepted By Operational Director`,
                      stepCssClass:
                        decoded.payment_request_confirmed_by_operational_director !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj6 = {
                      title: "Vice President",
                      content:
                        decoded.payment_request_confirmed_by_vice_president !== null
                          ? `Payment Request Has been Accepted by ${
                              decoded.vice_president.user_fullname
                            } at ${this.dateSimple(
                              decoded
                                .payment_request_confirmed_by_vice_president_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Payment Request Has not been Accepted By FA Coal Manager`,
                      stepCssClass:
                        decoded.payment_request_confirmed_by_vice_president !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    obj7 = {
                      title: "President Director",
                      content:
                        decoded.payment_request_confirmed_by_president_director !== null
                          ? `Payment Request Has been Accepted by ${
                              decoded.president_director.user_fullname
                            } at ${this.dateSimple(
                              decoded
                                .payment_request_confirmed_by_president_director_time,
                              "YYYY-MM-DD",
                            )}`
                          : `Payment Request Has not been Accepted By President Director`,
                      stepCssClass:
                        decoded.payment_request_confirmed_by_president_director !== null
                          ? "has-step-green"
                          : "has-step-red",
                    }
                    this.statusItems.push(obj5)
                    this.statusItems.push(obj6)
                    this.statusItems.push(obj7)
                  }
            axios
              .get(
                `https://api-proc.geolims.com/transactions/payment_requests/details/${decoded.payment_request_id}`,
                { headers },
              )
              .then(response2 => {
                if (response2.data.success === true && response2.data.data) {
                  if (decoded.payment_request_is_finished === true) {
                    this.isVisiblePrint = true
                  }
                  this.chosens = response2.data.data
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Get Data Failed",
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  })
                }
              })
    },
    submitConfirmPurchaseRequest() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
  const decoded = JSON.parse(localStorage.getItem("paymentRequestDetails"))
        const id = decoded.payment_request_id
        const body = {
        time: moment(),
        }
    axios
      .post(`${process.env.VUE_APP_API_URL}transactions/payment_requests/confirm/${this.currentRole}/${id}`, body, { headers })
      .then(async response => {
        this.submitButtonDisabled = false
        if (response.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Purchase Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'apps-procurement-payment-request-list' })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
                if (elem.value === val.value) {
                  same = true
                }
          })
          if (same === false) {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    unit_name: elem.unit_name, label: elem.label, value: elem.value, qty: 1,
                  }
                }
              })
            this.chosens.push(item)
          }
        } else {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    unit_name: elem.unit_name, label: elem.label, value: elem.value, qty: 1,
                  }
                }
            })
            this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
    previous() {
          this.$router.push({ name: 'apps-procurement-payment-request-list' })
    },
    },
  }
  </script>
